@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.find {

  &__search-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: $white;
    box-shadow: none;
    position: relative;
  }

  &__result-link-wrapper {
    margin-top: 20px;
    text-align: right;
  }

  &__title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #797979;
  }

  &__glossary {
    overflow: hidden;
    margin: 16px 0 20px 0;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);

  }

  &__article {
    box-shadow: 0 2px 8px rgba(9,88,112,.08);
  }

  &__box {
    position: relative;

    > div:nth-child(1) {
      padding-top: 20px;
      border-top: 1px solid $gallery;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;

      >div:nth-child(2) {
        border-top: none;
      }
    }
  }

  &__result-link {
    color: $cerulean;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 0.875rem;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  &__meta-bar {
    display: flex;
    justify-content: space-between;
  }

  &__filters {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__mobile-filters {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .box {
      padding: 0;
    }
  }

  &__filter-toggle {
    display: flex;
    justify-content: space-between;
    padding: 0.9375rem;
    font-family: 'Noto Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #797979;

    .find__mobile-filters & {
      max-height: 54px;
      flex-grow: 0;
    }

    .icon--arrow {
      width: 17px;
      height: 24px;
    }
  }

  &__filter-body {
    padding: 0.9375rem;
  }

  .search-bar {
    margin-bottom: 20px;
  }

  &__loader-search-field {
    overflow: hidden;
    max-height: 45px;

    .box {
      padding: 16px 1.875rem;
    }
  }

  &__feedback{
    margin-top: 15px;
  }

  &__feedback-title{
    font-family: 'Noto Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 28px !important;
    color: $near-bondi-blue;
  }

  &__feedback-text{
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $coal;
  }

  &__feedback-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}